<template>
  <van-tabbar v-model="active" route fixed placeholder safe-area-inset-bottom>
    <van-tabbar-item
      v-for="tabbar in tabbars"
      :key="tabbar"
      :name="tabbar.name"
      :icon="tabbar.icon"
      :to="tabbar.to"
      replace
    >
      {{tabbar.title}}
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
import { Tabbar, TabbarItem } from 'vant'

export default {
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem
  },
  name: 'FootTabbar',
  data () {
    return {
      tabbars: [
        { title: '首页', name: 'goods', icon: 'home-o', to: { name: 'goods'} },
        // { title: '购物车', name: 'cart', icon: 'cart-o', to: { name: 'cart'} },
        { title: '订单', name: 'order', icon: 'orders-o', to: { name: 'order'} }
      ]
    }
  },
  props: [ "active" ]
}
</script>

<style>

</style>