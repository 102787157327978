<template>
  <van-list class="goods-list"
    v-model:loading="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="onLoad"
  >
    <div class="item" v-for="item in goods" :key="item" @click="gotoGoodsDetail(item.id)">
      <div class="item-thumb"><img :src="item.picture" /></div>
      <div class="item-title">{{item.name}}</div>
      <div class="item-desc">{{item.summary}}</div>
      <div class="item-price"><span>&yen;</span>{{formatPrice(item.price)}}</div>
    </div>
  </van-list>

  <FootTabbar active="goods"></FootTabbar>
</template>

<script>
import { Toast, Dialog } from 'vant'
import FootTabbar from '../../components/foot-tabbar.vue'

export default {
  components: {
    FootTabbar
  },

  data() {
    return {
      finished: false,
      loading: false,
      goods: []
    }
  },

  computed: {
    
  },

  methods: {
    onLoad() {
      setTimeout(() => {
        this.axios
        .post('/api/mall/loadGoodsList')
        .then(res=> {
          console.log(res.data)
          if (res.data.success && res.data.data.list.length > 0) {
            var min = 0
            var max = 0
            if (this.goods.length > 0) {
              min = this.goods.length
              max = min + 10
            } else {
              min = 0
              max = 20
            }
            console.log(min, max)
            max = Math.min(max, res.data.data.list.length)
            for (let i = min; i < max; i++) {
              this.goods.push(res.data.data.list[i])
            }
            this.loading = false

            if (this.goods.length == res.data.data.list.length) {
              this.finished = true
            }
          } else {
            Toast(res.data.message)
          }
        })
      }, 1000)
    },

    formatPrice(price) {
      return (price / 100).toFixed(2)
    },

    gotoGoodsDetail(id) {
      this.$router.push({ name: 'goodsDetail', params: { id: id } })
    }
  },
};
</script>

<style lang="less">
.goods-list {
  width: 94%;
  margin: 2% auto;
  columns: 2;

  .item {
    width: 90%;
    break-inside: avoid;
    margin-bottom: 10%;
    background-color: #fff;
    padding: 5%;
    border-radius: 10px;

    div {
      margin-bottom: 6px;
    }

    &-thumb {
      text-align: center;

      img {
        width: 100%;
      }
    }

    &-title {
      font-size: 14px;
      font-weight: bold;
    }

    &-desc {
      font-size: 12px;
      color: #666;
      overflow: hidden;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      box-orient: vertical;
      display: -webkit-box;
      display: box;
    }

    &-price {
      color: #ff0000;
      font-size: 14px;

      span {
        font-size: 10px;
        margin-right: 5px;
      }
    }
  }
}
</style>